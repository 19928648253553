import styled from "styled-components";
import {MdClose} from "react-icons/md"

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 999;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation:fadein .5s;

    @media screen and (max-width: 768px){
        width: 100%;
        align-items: flex-start;
        overflow: scroll;
    }
    @keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
`
export const ModalWrapper = styled.div`
    max-width: 800px;
    width: 100%;
    height: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    color: #ee3424;
    position: relative;
    z-index: 10;
    border-radius: 10px;
`
export const ModalImg = styled.img`
    width: 50%;
    height: 100%;
    background: #000000;
    border-radius: 10px 0 0 0;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`
export const ModalContentTop = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 1.6;
    color: #141414;
    padding: 15px 15px 0;

    @media screen and (max-width: 768px){
        display: block;
    }
`
export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    color: #141414;
    padding: 15px;
`
export const ModalTitle = styled.h2`
    font-size: 1.8rem;
    font-weight: bold;
`
export const ModalText = styled.p`
    font-size: 1rem;
`
export const ModalClose = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    z-index: 10;
`
export const ListModal = styled.ul`
    display: block;
    text-align: left;
    list-style: none;
    padding-left: 15px;
`
export const ItemList = styled.li`
    font-size: 1rem;
`
export const ItemBold = styled.strong`
    font-weight: bold;
`