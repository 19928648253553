export const Title = {
    letter_red: 's',
    title_complete: 'erviços',
}
export const Email = {
    image: require('../sections/Servicos/icons/white/icon_email.png'),
}
export const Texto = {
    description: 'Você participa do projeto do início ao fim, trabalhando por meio de metodologias ágeis, cada assunto é abordado no momento certo, em plena sintonia, para definirmos juntos os objetivos e prioridades e assim entregar um software em um período mais curto, com menor risco de insatisfação e sem surpresa no orçamento.'
}
export const DataServicos = [
    {
        image: require('../sections/Servicos/icons/white/icon_network.png'),
        imageHover: require('../sections/Servicos/icons/red/icon_network.png'),
        alt: 'Intranet e extranet',
        legend: 'Intranet e extranet'
    },
    {
        image: require('../sections/Servicos/icons/white/icon_cart.png'),
        imageHover: require('../sections/Servicos/icons/red/icon_cart.png'),
        alt: 'comércio eletrônico, soluções B2C e B2B',
        legend: 'comércio eletrônico, soluções B2C e B2B'
    },
    {
        image: require('../sections/Servicos/icons/white/icon_browser.png'),
        imageHover: require('../sections/Servicos/icons/red/icon_browser.png'),
        alt: 'portais, websites e hotsites',
        legend: 'portais, websites e hotsites'
    },
    {
        image: require('../sections/Servicos/icons/white/icon_devices.png'),
        imageHover: require('../sections/Servicos/icons/red/icon_devices.png'),
        alt: 'soluções mobile: Ipad, Iphone e Android',
        legend: 'soluções mobile: Ipad, Iphone e Android'
    },
    {
        image: require('../sections/Servicos/icons/white/icon_analytics.png'),
        imageHover: require('../sections/Servicos/icons/red/icon_analytics.png'),
        alt: 'aplicações web, monitoramento e gestão em cloud',
        legend: 'aplicações web, monitoramento e gestão em cloud'
    },
];