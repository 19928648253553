import styled from "styled-components";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  width: 100%;
  background-color: transparent;
  color: #fff;
  margin: 0 15px;
  font-size: 4em;
`
export const ImgLogo = styled.img`
  display: block;
  margin:0;
  vertical-align: middle;
  width: 100%;
  height: auto;
`
export const Button = styled.button`
  position: relative;
  color: #ffffff;
  font-size: 1rem;
  padding: 10px 15px;
  border: 0;
  border-radius: 2px;
  background: #f3f3f3;
  text-align: center;
  cursor: pointer;
`
export const ArrowLeft = styled(MdKeyboardArrowLeft)`
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    width: 42px;
    height: 42px;
    padding: 0;
    z-index: 10;
    color: #58595b;
    -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    cursor: pointer;
    position: absolute;
    top: 50%;
  left: 50%;
    right: 0;
    width: 42px;
    height: 42px;
    padding: 0;
    z-index: 10;
    color: #58595b;
    -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`