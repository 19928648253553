import React from "react";
import { DataServicos, Email, Texto, Title } from "../../data/DataServicos";
import { Container, FirstLetterTitle, SectionContent, TitleSection, Text } from "../../globalStyles";
import { RowIcons,
    ColumnIcons,
    ImgIcon,
    LegendTxt,
    ImgIconHover,
    EmailBlock,
    InputBlock,
    IconMail,
    Input,
    Button
} from "./Servicos.elements";

const Servicos = () => {
    return (
        <SectionContent id="servicos" bgColor="#1a1a1a">

            <TitleSection><FirstLetterTitle>{Title.letter_red}</FirstLetterTitle>{Title.title_complete}</TitleSection>

            <Container>
                <Text color='#ffffff' pb='30px' pl='15px' pr='15px' textIndent='30px'>
                   {Texto.description}
                </Text>

                <RowIcons>
                    {DataServicos.map((servico, index) => (
                        <ColumnIcons key={index}>
                            <ImgIcon src={servico.image} alt={servico.alt} />
                            <ImgIconHover src={servico.imageHover} alt={servico.alt} />
                            <LegendTxt>{servico.legend}</LegendTxt>
                        </ColumnIcons>
                    ))}
                </RowIcons>
            </Container>

            <EmailBlock>
                <Container>
                    <InputBlock>
                        <IconMail src={Email.image} />
                        <Input type="email" placeholder="digite seu e-mail para receber novidades" required />
                        <Button type="submit">inscreva-se</Button>
                    </InputBlock>
                </Container>
            </EmailBlock>
        </SectionContent>
    )
}

export default Servicos