import styled from "styled-components";
import {FaWhatsapp, FaLinkedinIn, FaFacebookF, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'
import InputMask from "react-input-mask";

export const WhatsApp = styled(FaWhatsapp)`
  color: #ffffff;
  font-size: 2.4rem;
`
export const Facebook = styled(FaFacebookF)`
  color: #ffffff;
  font-size: 2.4rem;
`
export const Linkedin = styled(FaLinkedinIn)`
  color: #ffffff;
  font-size: 2.4rem;
`
export const Telefone = styled(FaPhoneAlt)`
  color: #ffffff;
  font-size: 2.4rem;
`
export const Localizacao = styled(FaMapMarkerAlt)`
  color: #ffffff;
  font-size: 2.4rem;
`
export const DivLogo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 340px;

  @media screen and (max-width: 768px){
  height: auto;
  justify-content: center;
  padding: 50px 0;
}
`
export const LogoScale = styled.img`
  max-width: 200px;
  @media screen and (max-width: 768px){

  }
`
export const List = styled.ul`
  width: auto;
  height: auto;
`
export const Item = styled.li`
  display: inline;
  padding-right: 40px;
`
export const LinkCNTT = styled.a`
  text-decoration: none;
`
export const RowContent = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
` 
export const ColumnContent = styled.div`
  position: relative;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
  padding: 0 15px;

  @media screen and (max-width: 800px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
` 
export const Input = styled(InputMask)`
  width: 100%;
  border: 0;
  border-radius: 0;
  background: rgb(89 89 92 / 0.5);
  color: #ffffff;
  height: 40px;
  margin-bottom: 8px;
  padding: 0 15px;
  font-size: 1rem;

  ::placeholder{
      color: #ffffff;
      font-size: 1rem;
      opacity: .5
  }
` 
export const TextArea = styled.textarea`
  margin-bottom: 5px;
  width: 100%;
  padding: 15px 20px;
  border: 0;
  border-radius: 0;
  background: rgb(89 89 92 / 0.5);
  color: #ffffff;
  min-height: 140px;
  font-size: 1rem;

  ::placeholder{
      color: #ffffff;
      font-size: 1rem;
      opacity: .5
  }
`
export const Select = styled.select`
  width: 100%;
  margin-bottom: 8px;
  padding: 0 15px;
  border: 0;
  border-radius: 0;
  background: rgb(89 89 92 / 0.5);
  color: #ffffff;
  height: 40px;
  font-size: 1rem;
` 
export const RowInput = styled.div`
  display: -ms-flexbox;
  display: flex;
  padding: 0;

  .inputTel{
    width: 100%;
    margin-bottom: 8px;
    padding: 0 15px;
    border: 0;
    border-radius: 0;
    background: rgb(89 89 92 / 0.5);
    color: #ffffff;
    height: 40px;
    font-size: 1rem;
  }
  .separador-l{
      width: 100%;
      padding-right: 5px;
  }
  .separador-r{
      width: 100%;
      padding-left: 5px;
  }
  @media screen and (max-width: 768px) {
    .separador-l{
      width: 100%;
      padding-right: 0;
    }
    .separador-r{
        width: 100%;
        padding-left: 0;
    }
  }
  @media screen and (max-width: 800px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
`
export const Formulario = styled.form`
  width: 100%;
`
export const InputFile = styled.div`
  background: rgb(89 89 92 / 0.5);
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${Input}{
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
`
export const Button = styled.button`
  border: 0;
  height: 45px;
  padding: 0 15px;
  min-width: ${(props) => props.mw};
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.br};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  border: 0;
  font-size: 1.1rem;
  font-weight: 500;
  float: right;
  cursor: pointer;

  :disabled{
    background: #a7a7a7
  }
`
export const BlockCapcha = styled.div`
    margin: 20px auto 5px;
    max-width: 304px;
    width: 100%;
`