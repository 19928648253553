import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
  height: auto !important;
}
body {
  opacity: 1;
  visibility: visible;
  animation: 0s ease 0s 1 normal none running none;
  overflow-x: hidden;
}
.rec-slider-container{
  margin: 0 !important;
}
`
export const FullContainer = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
`
export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 1170px;
margin-right: auto;
margin-left: auto;
padding-right: 15px;
padding-left: 15px;
padding-left: ${(props) => props.pl}};
padding-right: ${(props) => props.pr}};
margin-left: ${(props) => props.ml}};
margin-right: ${(props) => props.mr}};
max-width: ${(props) => props.mw}};

@media screen and (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`
export const SectionContent = styled.div`
    position: relative;
    background: ${(props) => props.bgColor};
    padding-bottom: ${(props) => props.pb};
    background-image: url(${(props) => props.imgBG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`
export const TitleSection = styled.h1`
  padding-top: 75px;
  padding-bottom: 75px;
  color: #59595c;
  font-size: 2.5rem;
  text-align: center;
`
export const FirstLetterTitle = styled.span`
  color: #ee3424;
`

export const Text = styled.p`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 1.1rem;
  text-indent: ${(props) => props.textIndent};
  padding-right: ${(props) => props.pr};
  padding-left: ${(props) => props.pl};
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  font-size: ${(props) => props.fz};
  font-weight: ${(props) => props.fb};
  text-align: ${(props) => props.ta};

`
export default GlobalStyle