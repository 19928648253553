import { Container, SectionContent, Text } from "../../globalStyles";

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Obtém o ano atual

    return(
        <SectionContent id="footer" bgColor="#ee3424">
            <Container>
                <Text fb="400" color="#ffffff" fz="1rem" pt="25px" pb="25px" ta="center">
                    Scale Systems - {currentYear} - Todos os Direitos Reservados
                </Text>
            </Container>
        </SectionContent>
    )
}

export default Footer