import React, { useEffect, useState } from "react";
import { TextCursor, TitleSlide } from "./VideoSlide.elements";
import PropTypes from 'prop-types';

const TypeWriter = ({ value }) => {
    const [text, setText] = useState('');

    useEffect(() => {
        const typeWriter = (i = 0) => {
            if (i < value.length) {
                setText((prevText) => prevText + value[i]); // Adiciona um caractere por vez
                setTimeout(() => {
                    typeWriter(i + 1); // Chama recursivamente para o próximo caractere
                }, 300);
            }
        };

        setText(''); // Reseta o texto ao iniciar a escrita
        typeWriter();

    }, [value]); // Apenas `value` como dependência

    return (
        <TitleSlide>
            {text}
            <TextCursor />
        </TitleSlide>
    );
}

TypeWriter.propTypes = {
    value: PropTypes.string.isRequired
};

export default TypeWriter;
