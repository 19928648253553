import React from 'react';
import GlobalStyle from './globalStyles';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FullContainer } from './globalStyles';
import { Navbar } from './components';
import Home from './sections/Home/Home';
import Cases from './sections/Cases/Cases'
import Sobre from './sections/Sobre/Sobre';
import Servicos from './sections/Servicos/Servicos';
import Contato from './sections/Contato/Contato';
import Footer from "./sections/Footer/Footer";

const App = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <FullContainer>
        <Navbar />
        <Home />
        <Cases />
        <Sobre />
        <Servicos />
        <Contato />
        <Footer />
      </FullContainer>
    </React.Fragment>
  )
}

export default App;
