import React from "react";
import { CarouselLogos } from "../../components";
import { Container, FirstLetterTitle, SectionContent, TitleSection, Text } from "../../globalStyles";
import { CardSobre, CardContent, CardLeft, CardRight, CardTitle, CardText, LastSobre } from "./Sobre.elements";

const Sobre = () => {
    return (
        <SectionContent id="sobre" pb='50px;'>

            <TitleSection><FirstLetterTitle>s</FirstLetterTitle>obre</TitleSection>

            <CardSobre>
                <Container pl='0' pr='60px' ml='auto' mr='auto' mw='1200px'>
                    <CardContent>
                        <CardLeft>
                            <CardTitle>DESENVOLVIMENTO DE SOFTWARE EM PLATAFORMA WEB</CardTitle>
                        </CardLeft>

                        <CardRight>
                            <CardText pb='15px'>
                                Somos uma empresa multidisciplinar de tecnologia sempre se atualizando para
                                desenvolver sistemas inteiramente personalizado para seu negócio, de acordo com
                                sua necessidade.
                            </CardText>
                            <CardText>
                                Mais do que a criação de um software, nos envolvemos nos projetos na busca de
                                compreender todos os procedimentos envolvidos na rotina a fim de dinamizá-los.
                                Trabalhamos com tecnologias voltadas para plataforma web por ter baixo impacto
                                na atualização de versões, baixa complexidade na análise de problemas, alta
                                responsividade em atualizações e correções, independência de plataforma e maior
                                flexibilidade na construção e definição de usabilidade.
                            </CardText>
                        </CardRight>
                    </CardContent>
                </Container>
            </CardSobre>

            <Container>
                <Text color='#58595b' pt='30px' pb='30px' pl='15px' pr='15px'>
                    Nossa missão é fazer com que o sistema desenvolvido se encaixe perfeitamente ao dia a dia da sua empresa, agilizando e facilitando os processos.
                </Text>
            </Container>

            <LastSobre>
                <Container pl='0' pr='0' ml='auto' mr='auto' mw='1200px'>
                    <CardContent>
                        <CardLeft>
                            <CardTitle size='2rem' color='#58595b'>+ 14 ANOS DE EXPERIÊNCIA</CardTitle>
                        </CardLeft>

                        <CardRight mr='0'>
                            <CarouselLogos />
                        </CardRight>
                    </CardContent>
                </Container>
            </LastSobre>

        </SectionContent>
    )
}

export default Sobre