import styled from "styled-components";

export const CardSobre = styled.div`
    background: #ee3424;
    position: relative;
    left: 8%;
    width: 92%;
    height: auto;
    padding: 20px 15px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 65%);

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
      left: 0;
  }
`
export const LastSobre = styled.div`
    position: relative;
    left: 8%;
    width: 92%;
    height: auto;
    padding: 20px 15px;

    @media screen and (max-width: 991px) {
      position: initial;
    width: 100%;
    padding: 0;
  }
`
export const CardContent = styled.div`
  position: relative;
  max-width: 1170px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`
export const CardLeft = styled.div`
    position: relative;
    padding: 15px 0;
    width: 22%;

@media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const CardRight = styled.div`
  position: relative;
  padding: 0 35px 0 0;
  padding-right: ${(props) => props.mr};
  width: 78%;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`
export const CardTitle = styled.h2`
    color: #ffffff;
    font-weight: 900;
    font-size: ${(props) => props.size};
    position: absolute;
    bottom: 0;
    padding: 0;
    color: ${(props) => props.color};

    @media screen and (max-width: 768px) {
      width: 100%;
      position: initial;
      padding: 0 15px 0;
  }
`

export const CardText = styled.p`
  color: #ffffff;
  font-weight: 500;
  text-align: justify;
  text-indent: 30px;
  font-size: 1.1rem;
  padding-bottom: ${(props) => props.pb};

  @media screen and (max-width: 768px) {
    text-align: initial;
  }
`
