import styled, { keyframes } from "styled-components";

export const WrapVideo = styled.div`
    position: relative;
    background: #000000;
    height: 100vh;
    overflow: hidden;

`
export const blinkTextCursor = keyframes`
  from { width: 0 }
  to { width: 100% }
`
export const TextCursor = styled.span`
  border-right: 3px solid rgba(255, 255, 255, .75);
  display: inline;
  padding-left: 5px;
  animation: ${blinkTextCursor} 3.5s steps(40, end),
  blink-caret .75s step-end infinite;

  /* The typing effect */
 ${blinkTextCursor} {
  from { width: 0 }
  to { width: 100% }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #999999; }
}
`
export const TitleSlide = styled.h1`
  letter-spacing: .5rem;
  text-shadow: 2px 1px 10px rgb(0 0 0 / 50%);
  font-size: 5rem;
  font-weight: 900;
  letter-spacing: .5rem;
  color: #59595c;
  
  ::first-letter{
    color: #ee3424;
  }

  @media screen and (max-width: 768px){
        font-size: 3rem;
    }
`
export const SlideItems = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000000;
`
export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(to top, transparent 0, #000000 20%);
  mask-image: linear-gradient(to top, transparent 0, #000000 20%);
`
export const ContentSlider = styled.div`
  position: absolute;
  top: 60%;
`
export const FontSmall = styled.small`
  font-size: 1.8rem;
  letter-spacing: .5rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 1px 10px #000000;
  animation: slidein 12s;

  @keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}
`