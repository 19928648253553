import React, { useRef } from "react";
import Carousel, { consts } from 'react-elastic-carousel'
import { DataLogos } from "../../data/DataSobre";
import { Item, ImgLogo, Button, ArrowLeft, ArrowRight } from "./CarouselLogos.elements";


const CarouselLogos = () => {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4.5 },
    { width: 1200, itemsToShow: 5 },
  ];
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(DataLogos.length);
    }
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    )
  }

  return (
    <Carousel
      breakPoints={breakPoints}
      ref={carouselRef}
      renderArrow={myArrow}
      enableAutoPlay={true}
      autoPlaySpeed={5000}
      onPrevStart={onPrevStart}
      onNextStart={onNextStart}
      pagination={false}
    >
      {DataLogos.map((logo, index) => (
        <Item key={index}><ImgLogo src={logo.image} alt={logo.alt} /></Item>
      ))}
    </Carousel>
  )
}

export default CarouselLogos