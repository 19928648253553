import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Background, ModalWrapper, ModalContent, ModalContentTop, ModalImg, ModalText, ModalClose, ListModal, ItemList, ItemBold } from "./Modal.elements";

const modalRoot = document.getElementById('modal-root')

class Modal extends Component {


    render() {

        return ReactDOM.createPortal(
            <React.Fragment>
                {this.props.show && (
                    <Background>
                        <ModalWrapper>

                            <ModalContentTop>
                                <ModalImg src={this.props.image} alt={this.props.alt_image} />
                                <ListModal>
                                    <ItemList><ItemBold>Cliente:</ItemBold> {this.props.clientName}</ItemList>
                                    <ItemList><ItemBold>Segmento:</ItemBold> {this.props.segment}</ItemList>
                                    <ItemList><ItemBold>Projeto:</ItemBold> {this.props.project}</ItemList>
                                </ListModal>
                            </ModalContentTop>

                            <ModalContent>
                                <ModalText>{this.props.description}</ModalText>
                                <ModalText>{this.props.description_two}</ModalText>
                            </ModalContent>

                            <ModalClose onClick={this.props.onHide} />

                        </ModalWrapper>
                    </Background>
                )}
            </React.Fragment>,
            modalRoot
        )
    }
}
export default Modal;