export const DataLogos = [
    {
        image: require('../images/logos/divihub-logo.png'),
        alt: 'Logo Divi Hub',
    },
    {
        image: require('../images/logos/levis-logo.png'),
        alt: 'Logo Levi`s',
    },
    {
        image: require('../images/logos/kinoplex-logo.png'),
        alt: 'Logo Kinoplex',
    },
    {
        image: require('../images/logos/cinesystem-logo.png'),
        alt: 'Logo Cinesystem',
    },
    {
        image: require('../images/logos/flix-logo.png'),
        alt: 'Logo Flix',
    },
    {
        image: require('../images/logos/target-logo.png'),
        alt: 'Logo Target Trading',
    },
];