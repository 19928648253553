import React from "react";
import { WrapVideo, Video, SlideItems, ContentSlider, FontSmall } from "./VideoSlide.elements";
import { DataVideos } from '../../data/DataVideos'
import { Container } from "../../globalStyles";
import Slider from "react-slick";
import TypeWriter from "./TypeWriter";

const SliderComponent = () => {

    const settings = {
        dots: false,
        lazyLoad: true,
        pauseOnHover: false,
        accessibility: false,
        draggable: false,
        fade: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        cssEase: "linear"
    };
    
    return (
        <WrapVideo>
            
            <Slider {...settings}>
                {DataVideos.map((slide, index) => (
                    <SlideItems key={index}>
            
                        <Video autoPlay playsInline muted loop>
                            <source src={slide.videoWebm} type="video/webm" />
                            <source src={slide.videoMp4} type="video/mp4" />
                        </Video>
                        <Container>
                            <ContentSlider>
                                <TypeWriter value={slide.title}/>
                                <FontSmall>{slide.subtitle}</FontSmall>
                            </ContentSlider>
                        </Container>

                    </SlideItems>
                ))}
            </Slider>
        </WrapVideo>
    )
}
const VideoSlide = () => {

    return (
        <SliderComponent slides={DataVideos} />
    )
}

export default VideoSlide